<script setup>
const currentYear = new Date().getFullYear();
</script>

<template>
  <footer>
    <div class="bottom-0 w-full flex flex-col align-bottom bg-gray-200 py-24 md:py-0 gap-4">
      <div class="flex flex-col md:flex-row items-start justify-evenly gap-4 px-6 py-6">
        <div class="flex flex-col items-center justify-center gap-2 w-full max-w-2xl">
          <img src="/images/logo_menor.png" alt="Logo Supermercado" class="w-[200px]" />
          <p class="text-sm">Dr. Gaspar Rodriguez de Francia.</p>
          <p class="text-sm">Santa Rita - Alto Paraná - Paraguay</p>
          <p class="text-sm">Telefonos: +595 673 220 270</p>
          <p class="text-sm">
            <a href="https://wa.me/595993276347" target="_blank">
              +595 993 276347
              <i class="fa-brands fa-whatsapp text-green-400" style="font-size: 20px"></i>
            </a>
          </p>
          <p class="border-b-[1.5px] border-gray-950">
            <strong>Horario de Atención</strong>
          </p>
          <p class="text-sm">
            Lunes a Sábados de 7:00 a 21:00 Hs.<br />
            Domingo de 7:00 a 13:00 Hs.<br />
          </p>
        </div>
        <div class="flex flex-col items-center justify-center gap-2 w-full max-w-2xl mt-10 md:mt-0">
          <img src="/images/logo_mayorista.png" alt="Logo Primavera Supermercado" class="w-[200px]" />
          <p class="text-sm">Dr. Gaspar Rodriguez de Francia.</p>
          <p class="text-sm">Santa Rita - Alto Paraná - Paraguay</p>
          <p class="text-sm">
            <a href="https://wa.me/595993286771" target="_blank">
              +595 993 286771
              <i class="fa-brands fa-whatsapp text-green-400" style="font-size: 20px"></i>
            </a>
          </p>
          <p class="border-b-[1.5px] border-gray-950">
            <strong>Horario de Atención</strong>
          </p>
          <p class="text-sm">Lunes a Sábados de 7:00 a 18:00 Hs.<br /></p>
        </div>
        <div class="flex flex-col items-left gap-2">
          <h2 class="text-lg font-semibold">Informaciones</h2>
          <NuxtLink to="/" class="text-sm mt-4"> Inicio </NuxtLink>
          <NuxtLink to="/#promociones" class="text-sm mt-4"> Promociones </NuxtLink>
          <NuxtLink to="/acerca-de" class="text-sm mt-4"> Sobre Nosostros </NuxtLink>
          <NuxtLink to="/contacto" class="text-sm mt-4"> Contacto </NuxtLink>
        </div>
      </div>
      <div class="flex flex-col w-full items-center justify-center">
        <p class="font-bold text-md">AVISOS</p>
        <p class="text-sm whitespace-pre-wrap leading-6">Las fotos son para fines ilustrativos. Precios y Promociones estan sujetos a variaciones sin previo aviso.</p>
      </div>
      <div class="flex w-full gap-3 md:gap-0 flex-col md:flex-row items-center justify-between py-1 px-10 border-t-[1.0px] border-gray-400/30">
        <div>
          <p class="text-sm">Copyright 2024 - {{ currentYear }} © Supermercado Primavera. Todos los Derechos Reservados.</p>
        </div>
        <BlockSocialMedia />
      </div>
    </div>
  </footer>
</template>

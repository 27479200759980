<script setup>
</script>

<template>
  <div class="flex flex-col md:flex-row gap-5 items-center">
    <h3 class="font-semibold">
      ¡Síguenos en nuestras redes sociales!
    </h3>
    <div class="flex gap-3">
      <a href="https://www.facebook.com/primaverasupermercado" target="_blank">
        <i class="fa-brands fa-facebook text-blue-600" style="font-size: 30px;"></i>
        
      </a>
      <a href="https://www.instagram.com/supermercadoprimavera" target="_blank">
        <i class="fa-brands fa-instagram" style="font-size: 30px;"></i>
      </a>      
    </div>
  </div>
</template>

<style scoped></style>

<script setup>
const toggle = (event) => {
  menu.value.toggle(event);
};

const menu = ref();
const items = ref([
  {
    label: 'MENU',
    items: [
      {
        label: 'Inicio',
        icon: 'fa-solid fa-house px-2 py-1',
        route: '/',
      },
      {
        label: 'Consulte su Factura',
        icon: 'fa-solid fa-file-invoice px-2 py-1',
        route: '/consulta-factura',
      },
      {
        label: 'Promociones',
        icon: '',
        route: '/#promociones',
      },
      {
        label: 'Sobre Nosotros',
        icon: '',
        route: '/acerca-de',
      },
      {
        label: 'Contacto',
        icon: '',
        route: '/contacto',
      },
    ],
  },
]);
</script>

<style scoped>
.p-menu {
  background: red;
}
</style>

<template>
  <!--HEADER-->
  <header class="w-full bg-[#414141] h-28 px-2">
    <div class="h-28 w-full flex items-center justify-between max-w-7xl mx-auto">
      <div class="flex items-center">
        <NuxtLink to="/">
          <img src="/images/logo_menor.png" alt="Logo Supermercado Primavera" class="w-32 mr-7" />
        </NuxtLink>
        <nav class="hidden md:flex items-center gap-5">
          <NuxtLink to="/#promociones" class="text-white font-medium hover:bg-slate-900 px-2 py-2 rounded-lg duration-300">Promociones</NuxtLink>
          <NuxtLink to="/acerca-de" class="text-white font-medium hover:bg-slate-900 px-2 py-2 rounded-lg duration-300">Sobre Nosotros</NuxtLink>
          <NuxtLink to="/contacto" class="text-white font-medium hover:bg-slate-900 px-2 py-2 rounded-lg duration-300">Contacto</NuxtLink>
        </nav>
      </div>

      <div class="flex items-center">
        <template v-if="$device.isMobile">
          <Button type="button" icon="fa-solid fa-bars" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" class="bg-[#414141]" />
          <Menu :model="items" ref="menu" id="overlay_menu" :popup="true" :baseZIndex="100" :ptOptions="{ mergeSections: false, mergeProps: true }" :pt="{ root: { class: ['bg-[#414141] w-full'] } }">
            <template #item="{ item, props }">
              <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                  <span :class="item.icon" />
                  <span class="ml-2">{{ item.label }}</span>
                </a>
              </router-link>
              <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </template>
          </Menu>
        </template>

        <NuxtLink to="/consulta-factura" v-if="!$device.isMobile">
          <button type="button" class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-1 mb-2">
            Consulte su Factura
            <i class="fa-solid fa-file-invoice px-2 py-1" style="font-size: 24px"></i>
          </button>
        </NuxtLink>
      </div>
    </div>
  </header>
  <!--/HEADER-->
</template>
